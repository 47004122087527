import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"
import Bradcam from "../components/bradcam"

const PropertyPage = () => (
  <Layout>
    <SEO title="Property" />
    <Bradcam siteTitle="Property"></Bradcam>


  </Layout>
)

export default PropertyPage
